import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const Footers = () => {
    const today = new Date();
    const year = today.getFullYear();
  return (
    <>
        <footer id='footer'>
            <div className='footer-block'>
                <Container>
                    <div className='footer-box'>
                        <p>© {year} All Rights Reserved By Citizenship</p>
                        <div className='footer_links'>
                            <ul>
                                <li><Link to={'/home/terms-conditions'}>Terms & Conditions</Link></li>
                                <li><Link to={'/home/privacy-policy'}>Privacy Policy</Link></li>
                                <li><Link to={'/home/cookie-policy'}>Cookie Policy</Link></li>
                            </ul>
                        </div>                        
                    </div>
                </Container>
            </div>
        </footer>
    </>
  )
}

export default Footers;