import { Link } from 'react-router-dom';

const Errors = () => {
    return(
        <>
          <div id='notfound'>
                <div>
                    <p>404 | Page not Found !!</p>
                    <Link className='btn btn-red' to={'/'}>GO TO HOMEPAGE</Link>
                </div>
          </div>
        </>
    );
}

export default Errors