import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaUser, FaRightFromBracket, FaHouse } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { $ } from 'react-jquery-plugin';

const Menu = () => {
  const [logoutTimer, setLogoutTimer] = useState(null);
  const [isLogin, setLogin] = useState(false);
  const [isSubscription, setSubscription] = useState(false);
  const navigate = useNavigate();
  const [usersItems, setUsersItems] = useState([]);
  const [isNav, setNav] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('userToken')) {

      setLogin(true);
      setUsersItems(JSON.parse(localStorage.getItem('userInfo')));

      setInterval(() => {
        let subscrip = localStorage.getItem('subscriptionExpired')
        if (subscrip == 0) {
          setSubscription(true);
        }
        else {
          setSubscription(false);
        }
      }, 2000);
    }
    else {
      setLogin(false);


      const logoutUser = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userRegisterInfo');
        setLogin(false);
        navigate("/");
        window.location.reload(true);
        console.log("Logging out...");
    };

      // Function to reset the logout timer
      const resetTimer = () => {
        if (logoutTimer) {
          clearTimeout(logoutTimer);
        }
        setLogoutTimer(setTimeout(logoutUser, 50 * 60 * 1000)); // 50 minutes in milliseconds
      };

      // Event listeners for user activity
      const resetTimerOnUserActivity = () => {
        resetTimer();
      };

      window.addEventListener('click', resetTimerOnUserActivity);
      window.addEventListener('keypress', resetTimerOnUserActivity);

      // Initial timer setup
      resetTimer();

      // Cleanup function to remove event listeners and clear timeout on unmount or component re-render
      return () => {
        window.removeEventListener('click', resetTimerOnUserActivity);
        window.removeEventListener('keypress', resetTimerOnUserActivity);
        clearTimeout(logoutTimer);
      };
      
    }

    if($(window).width() <= 1024)
    {
        $(document).on("click","#nav ul li a",function(e)
        {          
            $("#nav ul").removeClass('open');
            setNav(false)
        });
    }
  }, [])

  function logOut() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userRegisterInfo');
    setLogin(false);
    navigate("/");
    window.location.reload(true);
  }

  return (
    <>
      <div className="main-nav">
        <nav id="nav">
          <button onClick={() => setNav(!isNav)} className="menu-icon"><span></span><span></span><span></span></button>
          <ul className={isNav ? 'nav open': 'nav'}>
            {isLogin ? <div className="username"><p>Hello, {usersItems.first_name} {usersItems.last_name}</p></div>: null}
            <li><Link to={'/'}>Home</Link></li>
            {isLogin ? (
              <div className="rdblock">
                  {isSubscription ? <li className='btns'><Link to={'/dashboard/'}>Dashboard</Link></li>:null}
                  <li className='btns'><Link to={'/dashboard/profile'}>Profile</Link></li>
                  
              </div>
            ) : (
              <>
                <li className='btns'><Link className='btn btn-red' to={'/home/auth/login'}>Log In</Link></li>
                <li className='btns'><Link className='btn btn-red' to={'/home/auth/register'}>Register</Link></li>
              </>
            )}
            <li className='btns'><Link className='btn btn-red' to={'/home/practice-test'}>Practice Test</Link></li>
            {isLogin ? <li className='btns rdblock'><Link className='btn btn-red' onClick={logOut}>Log Out</Link></li> : null}
          </ul>
          {isLogin ? (
            <div className='users'>
              <p>Hello, {usersItems.first_name} {usersItems.last_name}</p>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <div className='userio'>
                    <FaUser />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isSubscription ? <Dropdown.Item href="/dashboard/"><FaHouse className="io" /> Dashboard</Dropdown.Item> : null}

                  <Dropdown.Item href="/dashboard/profile"><FaUser className="io" /> Profile</Dropdown.Item>
                  <Dropdown.Item onClick={logOut}><FaRightFromBracket className="io" /> Log Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </nav>
      </div>
    </>
  )
}

export default Menu;