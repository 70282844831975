import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { signupSchema } from '../schemas';
import { postRegiter, postRegiterOtp } from '../apiservices/ApiServices';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Logoimg from '../assets/images/logo.webp';
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaEnvelope, FaMobileScreenButton } from "react-icons/fa6";
import Loderimg from '../assets/images/loder.gif';


const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    otp: '',
}

const Register = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
                navigate("/dashboard");       
            }
            else{
                navigate("/");
            }
        }
    }, [navigate])

    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [dataId, setDataId] = useState('');


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signupSchema,
        onSubmit: (vlaues, action) => {
            setLoading(true)
            postRegiter(vlaues).then((res) => {
                try {
                    if (res.data.success === true) {
                        setLoading(false);
                        toast.success(res.data.message);
                        localStorage.setItem('userRegisterInfo', JSON.stringify(res.data.data.user));
                        setDataId(res.data.data.user.id)
                        action.resetForm();
                        setSuccess(true);
                    }
                    else {
                        setLoading(false);
                        if(res.data.data.mobile)
                        {
                            toast.error(res.data.data.mobile[0]);
                        }

                        if(res.data.data.email)
                        {
                            toast.error(res.data.data.email[0]);
                        }                        
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });

        }
    })

    function getOtp() {
        setLoading(true)
        postRegiterOtp(values.mobile).then((res) => {
            try {
                if (res.data.success === true) {
                    setLoading(false);
                    toast.success(res.data.message);                   
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        });
    }
    return (
        <>
            <div id='auth'>
                <div className='auth-block regiter'>
                    <ToastContainer />
                    <Container>
                        <div className='auth-forms'>
                            <div className='logo_box'>
                                <img src={Logoimg} alt='Logo' title='Logo' />
                            </div>
                            <div className='formsboxs'>
                                {isSuccess ? (
                                    <div className='w-100'>
                                        <div className='titles'>
                                            <h4>Thank You For Registration</h4>
                                            <p>Kindly check your Email to activate your account.</p>
                                        </div>
                                        <div className='custom_btns'>
                                            <Link to={'/'} className='btn btn-red'>Ok</Link>
                                        </div>
                                    </div>
                                ) : (
                                    <form id='regiserform' onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <div className='form-group'>
                                                    <label htmlFor='firstName'><div className='icon'><FaUser /></div> First Name</label>
                                                    <input type="text"
                                                        name='firstName'
                                                        id='firstName'
                                                        placeholder='Enter First Name'
                                                        className='form-control'
                                                        autoComplete='off'
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.firstName && touched.firstName ? (
                                                        <p className='errormsg'>{errors.firstName}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='form-group'>
                                                    <label htmlFor='lastName'><div className='icon'><FaUser /></div> Last Name</label>
                                                    <input type="text"
                                                        name='lastName'
                                                        id='lastName'
                                                        placeholder='Enter Last Name'
                                                        className='form-control'
                                                        autoComplete='off'
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.lastName && touched.lastName ? (
                                                        <p className='errormsg'>{errors.lastName}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className='form-group'>
                                                    <label htmlFor='email'><div className='icon'><FaEnvelope /></div> Email</label>
                                                    <input type="email"
                                                        name='email'
                                                        id='email'
                                                        placeholder='Enter Email Address'
                                                        className='form-control'
                                                        autoComplete='off'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <p className='errormsg'>{errors.email}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='form-group'>
                                                    <label htmlFor='mobile'><div className='icon'><FaMobileScreenButton /></div> Mobile No.</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">+44</span>
                                                        <input type="tel"
                                                            name='mobile'
                                                            id='mobile'
                                                            placeholder='Enter Mobile No.'
                                                            className='form-control'
                                                            maxLength={10}
                                                            aria-describedby="basic-addon1"
                                                            autoComplete='off'
                                                            value={values.mobile}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                    {!errors.mobile && values.mobile ? (
                                                        <div className='linksbtns'>
                                                            <button type='button' onClick={getOtp}>Get OTP</button>
                                                        </div>
                                                    ) : null}

                                                    {errors.mobile && touched.mobile ? (
                                                        <p className='errormsg'>{errors.mobile}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='form-group'>
                                                    <label htmlFor='otp'><div className='icon'><FaMobileScreenButton /></div> OTP Verification</label>
                                                    <input type="tel"
                                                        name='otp'
                                                        id='otp'
                                                        placeholder='Enter OTP'
                                                        className='form-control'
                                                        autoComplete='off'
                                                        maxLength={4}
                                                        value={values.otp}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {!errors.mobile && values.mobile && !errors.otp && values.otp ? (
                                                        <div className='linksbtns'>
                                                            <button type='button' onClick={getOtp}>Resend</button>
                                                        </div>
                                                    ) : null}
                                                    {errors.otp && touched.otp ? (
                                                        <p className='errormsg'>{errors.otp}</p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className='buttonbox'>
                                            <button type='submit' className='btn btn-red'>Submit</button>
                                        </div>
                                        <div className='linksbox'>
                                            <p>Already have an account ? <Link to={'/home/auth/login'}>Login</Link></p>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </Container>
                </div>

                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : null}


            </div>
        </>
    )
}

export default Register;