import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Logoimg from '../assets/images/logo.webp';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Loderimg from '../assets/images/loder.gif';

import { postActiveEmail, postUserInfo, postPayment } from '../apiservices/ApiServices';

const Subscription = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [datas, setData] = useState([]);
    const today = new Date();
    const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    const { id, token } = useParams();

    useEffect(() => {
        if (!token) {
            postUserInfo(id).then((res) => {
                try {
                    if (res.data.success == true) 
                    {
                        setLoading(false);
                        setData(res.data.data.user)
                    }
                    else {
                        setLoading(false);
                        toast.error(res.data.data.user);
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });
        }
        else {
            postActiveEmail(id, token).then((res) => {
                try {
                    if (res.data.success === true) {
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        toast.error(res.data.data.user);
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });

            postUserInfo(id).then((res) => {
                try {
                    if (res.data.success == true) {
                        setLoading(false);
                        setData(res.data.data.user)
                    }
                    else {
                        setLoading(false);
                        toast.error(res.data.data.user);
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });
        }
    }, [0])
    const SubscriptionButtonWrapper = () => {
        return (<PayPalButtons
            createSubscription={(data, actions) => {
                return actions.subscription
                    .create({
                        //plan_id: "P-3YN26941WS3932432MVUJMDY",
                        plan_id: 'P-72C43336Y9065192JMYVXA7Y',
                    });

            }}
            onApprove={(data, actions) => {
                let id = datas.id;
                let orderID = data.orderID;
                let subscriptionID = data.subscriptionID;
                let accessToken = data.facilitatorAccessToken;
                let transaction_date = date;
                postPayment(id, orderID, transaction_date, subscriptionID, accessToken).then((res) => {
                    try {
                        if (res.data.success == true) {
                            toast.success(res.data.message);
                            localStorage.setItem('subscriptionExpired', 0);
                            setTimeout(() => navigate("/dashboard"), 3000);
                        }
                        else {
                            toast.error(res.data.data.user);
                        }
                    } catch (error) {
                        console.error(error);
                    } finally {

                    }
                });

                console.log(data);
            }}
            style={{
                label: "subscribe",
            }}

        />);
    }

    //console.log('newData => '+data);
    return (
        <>
            <div id='auth'>
                <div className='auth-block regiter'>
                    <ToastContainer />
                    <Container>
                        <div className='auth-forms'>
                            <div className='logo_box'>
                                <img src={Logoimg} alt='Logo' title='Logo' />
                            </div>
                            <div className='formsboxs'>
                                {!isLoading ? (
                                    <div className='w-100'>
                                        <div className='titles'>
                                            <h4>Hello, {datas.first_name} {datas.last_name}</h4>
                                            {!token ? null : <p>Thank You, for activate your account</p>}
                                            <p>Would you like to Subscribe for full Version?</p>
                                            <p>Monthly Subscription Fees £5</p>
                                        </div>
                                        <div className='custom_btns btngroups'>
                                            <div className='paypals'>
                                                <PayPalScriptProvider
                                                    options={{
                                                        "client-id": "AVvFT9qvr1QxZT8nK-hdHeYgX4Sc79iX3zW_YJiddRKanWqTOi6OD00PyjXog4mO8-KtY5Tm7X6x1SGP",
                                                        components: "buttons",
                                                        intent: "subscription",
                                                        vault: true,
                                                    }}
                                                >
                                                    <SubscriptionButtonWrapper />
                                                </PayPalScriptProvider>
                                            </div>
                                            <div>
                                                <Link to={'/'} className='btn btn-red'>Cancel</Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='pageloder'>
                                        <img src={Loderimg} alt='Loder' title='Loder' />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Subscription
