import React from 'react'

const Content = (props) => {
  return (
    <>
        <p className={props.CName ? props.CName : null}>{props.Content}</p>
    </>
  )
}

export default Content