import React, { useEffect, useState } from "react";
import { $ } from 'react-jquery-plugin';

import Headers from '../../includes/Headers/Headers';
import Footers from "../../includes/Footers/Footers";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getAttemptedQuestions } from "../../apiservices/ApiServices";
import ChartCard from '../../components/ChartCard/ChartCard';
import ChartImg from '../../assets/images/chart.webp';
import Loderimg from '../../assets/images/loder.gif';

const TestDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [data, setdata] = useState([]);

    console.log(id)
    useEffect(() => {
        if (!localStorage.getItem('userToken')) {
            navigate("/");
        }
        else {
            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
            getAttemptedQuestions(id).then((res) => {
                try {
                    setdata(res.data.data);
                    console.log(res.data.data)
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            $(document).on("click", ".question_list .indicats", function () {
                var value = $(this).attr('data-filter');

                if (value === "all") {
                    $('#testlistbox .test_list .formsbox').show();
                }
                else {
                    $("#testlistbox .test_list .formsbox").not('.' + value).hide();
                    $('#testlistbox .test_list .formsbox').filter('.' + value).show();
                }

                $('.question_list ul li .indicats').removeClass('active');
                $(this).addClass("active");
            });
            }
            else {
                navigate("/dashboard/profile");
            }
        }
    }, [])

    return (
        <>
            <Headers />
            <div className='pagewapper'>
                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : (
                    <Container>
                        <div className='pagetitles_box'>
                            <div className='left_boxs'>
                                <h1>Pass Detail</h1>
                                <p><Link to={'/dashboard'}>Dashboard</Link> {'>'} <Link to={'/dashboard/start-test'}>Start Test</Link>  {'>'}  {data.userQuestionSet.question_set_name} Detail</p>
                            </div>
                            <div className='right_boxs'>
                                <div className='test_boxs'>
                                    {data.userQuestionSet.isPass == 1 ? <p className='passed'>Passed</p> : <p className='failed'>Failed</p>}
                                </div>
                            </div>
                        </div>
                        <div className='chartslist'>
                            <Row>
                                <Col md={4}>
                                    <ChartCard Title="Total Attempted Questions" DataValue={data.userQuestionSet.total_attempted} ImgUrl={ChartImg} />
                                </Col>
                                <Col md={4}>
                                    <ChartCard Title="Total Correct Answers" DataValue={data.userQuestionSet.total_correct} ImgUrl={ChartImg} />
                                </Col>
                                <Col md={4}>
                                    <ChartCard Title="Total Wrong Answers" DataValue={data.userQuestionSet.total_wrong} ImgUrl={ChartImg} />
                                </Col>
                            </Row>
                        </div>
                        <div className='pagetitles_box pa-10'>
                            <div className='left_boxs'>
                                <h1>{data.userQuestionSet.question_set_name} - {data.userQuestionSet.attempted_date}</h1>
                            </div>
                            <div className='right_boxs'>
                                <ul>
                                    <li>
                                        <div className='colorboxs' style={{ background: '#64B66C' }}></div>
                                        <p>Correct Answer</p>
                                    </li>
                                    <li>
                                        <div className='colorboxs' style={{ background: '#F7736C' }}></div>
                                        <p>Wrong Answer</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div id='detailstestbox'>
                            <Row>
                                <Col md={6}>
                                    <div id='testlistbox'>
                                        <div className='test_list'>
                                            {data.questions.map((item, index) => {
                                                let num = index + 1
                                                return (
                                                    <div className={'formsbox que' + num}>
                                                        <div className="qustions">
                                                            <p>Q.{num}. {item.question}</p>
                                                            <div className="audio"></div>
                                                        </div>
                                                        <div className="options">
                                                            {item.options.map((items) => {
                                                                return (
                                                                    <div className={items.is_correct == 1 ? 'radioboxs active' : 'radioboxs'} key={items.id}>
                                                                        {items.userSelected == 1 ? (
                                                                            <input type='radio' name={'qus' + index} data-val={items.is_correct} disabled={true} checked />
                                                                        ) : (
                                                                            <input type='radio' name={'qus' + index} data-val={items.is_correct} disabled={true} />
                                                                        )}

                                                                        <div className='rdobtns'>
                                                                            <div className='checkmark'></div>
                                                                            <p>{items.option} </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>   
                                                        {item.options.map((items) => {
                                                            return (
                                                                <>
                                                                    {items.userSelected == 1 ? (
                                                                        <>
                                                                            {items.is_correct == 1 ? null : (<>
                                                                            {item.answer_desc ? (
                                                                                <div className='incorrectbox'>
                                                                                    <h4>Incorrect</h4>
                                                                                    <p>{item.answer_desc}</p>
                                                                                </div>
                                                                            ) : null}
                                                                            </>)}
                                                                        </>

                                                                    ) : null}
                                                                </>
                                                            );
                                                        })}

                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='questionsbox'>
                                        <h4>Total Questions</h4>
                                        <div className='question_list'>
                                            <ul>
                                                {data.questions.map((item, index) => {
                                                    let num = index + 1
                                                    return (
                                                        <li key={'quesList' + num}>
                                                            <button type="button" className={num === 1 ? 'indicats active' : 'indicats'} data-filter={'que' + num}>{num}</button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                )}
            </div>
            <Footers />
        </>
    )
}

export default TestDetails;