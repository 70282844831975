import React, { useEffect, useState, useRef } from "react";
import { $ } from 'react-jquery-plugin';
import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/PageTitle';
import Clock from '../../assets/images/clock.png';

import { useNavigate } from "react-router-dom";
import Loderimg from '../../assets/images/loder.gif';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPracticeQuestions } from "../../apiservices/ApiServices";

import ChartCard from "../../components/ChartCard/ChartCard";
import ChartImg from '../../assets/images/chart.webp';

const PracticeTest = () => {
    const navigate = useNavigate();
    const [number, setNumber] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [isLoadings, setLoadings] = useState(false);

    const [isDisabled, setDisabled] = useState(false);

    const [isResult, setResult] = useState(false);

    const [isData, setData] = useState([]);
    const [isques, setQues] = useState([]);

    const [secounds, setSecounds] = useState(59);
    const [minutes, setMinutes] = useState(44);

    const audioRef = useRef(null);

    const totalCorrectAnswers = [];
    const totalWrongAnswers = [];
    const totalMstype = [];

    useEffect(() => {
        getPracticeQuestions().then((res) => {
            try {
                setData(res.data.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        });

        var dataArray = { "testData": { "optionData": [] } }
        var datachkms = [];
        $(document).on("click", ".radioboxs input", function () {
            var typecode = $(this).attr('data-typecode');
            var id = $(this).attr('data-id');
            var question_id = $(this).attr('data-qesid');
            var option = $(this).attr('data-option');
            var is_correct = $(this).attr('data-val');

            if (typecode == 'MS') {
                datachkms.push({ "is_correct": is_correct });
                $(this).attr("disabled", true);
                
                if (datachkms.length == 2) {
                    if (datachkms[0].is_correct == 1 && datachkms[1].is_correct == 1) {
                        dataArray.testData.optionData.push({"id": id, "question_id": question_id, "option": option, "is_correct": 1 })
                        setQues(dataArray);
                    }
                    else
                    {
                        dataArray.testData.optionData.push({"id": id, "question_id": question_id, "option": option, "is_correct": 0})
                        setQues(dataArray);
                    }

                    $(this).parents('fieldset').find('input').attr("disabled", true);
                    $(this).parents('fieldset').find('button').show();
                    datachkms = [];
                }
            }
            else {
                if (is_correct == 0) {
                    $(this).parents('.formsbox').find('.incorrectbox').show();
                    $(this).parents('.formsbox').find('input[data-val="1"]').parent().addClass('active');
                }

                $(this).parents('fieldset').find('input').attr("disabled", true);
                $(this).parents('fieldset').find('button').show();

                dataArray.testData.optionData.push({"id": id, "question_id": question_id, "option": option, "is_correct": is_correct })
                setQues(dataArray);
            }
        });

        $(document).on('click', '.next', function (e) {
            var curent_fs = $(this).parent()
            var next_fs = $(this).parent().next();

            curent_fs.hide();
            next_fs.show();
        });
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            if (secounds > 0) {
                setSecounds(secounds - 1);
            }
            if (secounds === 0) {
                if (minutes === 0) {
                    clearInterval(timer);
                    if (isques.length === 0) {
                        var dataArray = { "testData": { "optionData": [] } }
                        setQues(dataArray);
                    }
                    submitForm();
                } else {
                    setMinutes(minutes - 1);
                    setSecounds(59);
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [secounds, minutes]);


    const handelClick = () => {
        setNumber(number + 1);
        audioRef.current.pause();
    }



    const submitForm = () => {
        setLoadings(true);

        const answerList = JSON.stringify(isques);
        setDisabled(true);
        console.log(answerList);
        toast.success('Test Submit Successfully');
        localStorage.setItem('P_Test', answerList);

        setTimeout(() => setResult(true), 3000);
        setTimeout(() => setLoadings(false), 3000);
    }

    return (
        <>
            <Headers />
            <section id='practice'>
                <div className='practice-block'>
                    <ToastContainer />
                    {isLoading ? (
                        <div className='pageloder'>
                            <img src={Loderimg} alt='Loder' title='Loder' />
                        </div>
                    ) : (
                        <Container>
                            <div className='tests-box'>
                                <PageTitle CName='section-title' Title="Practice Test" />
                                <Row>
                                    <Col md={6}>
                                        <div className='test_header'>
                                            <h4>Question {number} of {isData.questions.length}</h4>
                                            {isDisabled ? null : (
                                                <div id='timer'>
                                                    <img src={Clock} alt="Clock" title="Clock" />
                                                    <p>Remaining Time -  {minutes < 10 ? "0" + minutes : minutes}:{secounds < 10 ? "0" + secounds : secounds} </p>
                                                </div>
                                            )}
                                        </div>
                                        <form id="msform">
                                            <div id='nextsteps_forms'>
                                                {isData.questions.map((item, index) => {
                                                    return (
                                                        <fieldset key={item.id}>
                                                            <div className='formsbox'>
                                                                <div className='qustions'>
                                                                    <p>Q.{index + 1}. {item.question}</p>
                                                                    {item.audio != null ? (
                                                                        <audio controls ref={audioRef}>
                                                                            <source src={item.audio} type="audio/ogg" />
                                                                            <source src={item.audio} type="audio/mpeg" />
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                    ) : null}
                                                                </div>
                                                                <div className='options'>
                                                                    {item.options.map((items) => {
                                                                        return (
                                                                            <div className='radioboxs' key={items.id}>
                                                                                <input type='checkbox' name={'qus' + index} data-typecode={item.question_type_code} data-id={items.id} data-qesid={items.question_id} data-val={items.is_correct} data-option={items.option} />
                                                                                <div className='rdobtns'>
                                                                                    <div className='checkmark'></div>
                                                                                    <p>{items.option} </p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                {item.answer_desc ? (
                                                                    <div className='incorrectbox'>
                                                                        <h4>Incorrect</h4>
                                                                        <p>{item.answer_desc}</p>
                                                                    </div>
                                                                ) : null}

                                                            </div>
                                                            {isData.questions.length === index + 1 ? <button type="button" onClick={submitForm} className={isDisabled ? 'isDisabled btn btn-red' : 'btn btn-red'} >Submit</button> : <button type="button" onClick={handelClick} className='btn btn-red next'>Next</button>}

                                                        </fieldset>
                                                    );
                                                })}
                                            </div>
                                        </form>
                                    </Col>
                                    {isResult ? (
                                        <Col md={6}>
                                            <div className="resultboxs">
                                                {isques.testData.optionData.map((item) => {
                                                    if (item.is_correct != 0) {
                                                        totalCorrectAnswers.push(item.is_correct)
                                                    }
                                                    else {
                                                        totalWrongAnswers.push(item.is_correct)
                                                    }
                                                })}
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="button_box">
                                                            <p>You have
                                                                {isData.questions.length / 2 <= totalCorrectAnswers.length ? (
                                                                    <span className="btn btn-green">Pass</span>
                                                                ) : (
                                                                    <span className="btn btn-red">Failed</span>
                                                                )}
                                                                the test. Practice by doing more tests.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <ChartCard Title="Total Correct Answers" DataValue={totalCorrectAnswers.length} ImgUrl={ChartImg} />
                                                    </Col>
                                                    <Col md={12}>
                                                        <ChartCard Title="Total Wrong Answers" DataValue={totalWrongAnswers.length} ImgUrl={ChartImg} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                            </div>
                        </Container>
                    )}
                    {isLoadings ? (
                        <div className='pageloder'>
                            <img src={Loderimg} alt='Loder' title='Loder' />
                        </div>
                    ) : null}
                </div>
            </section>
            <Footers />
        </>
    )
}

export default PracticeTest;