
import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from './home/Home';
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Subscription from "../Auth/Subscription";
import Errors from './errors/Errors';
import Dashboard from "./Dashboard/Dashboard";
import StartTest from "./StartTest/StartTest";
import TestDetails from "./TestDetails/TestDetails";
import PracticeTest from "./PracticeTest/PracticeTest";
import Profile from "./Profile/Profile";
import AttemptTest from "./AttemptTest/AttemptTest";
import TermsConditions from "./TermsPolicy/TermsConditions";
import PrivacyPolicy from "./TermsPolicy/PrivacyPolicy";
import CookiePolicy from "./TermsPolicy/CookiePolicy";

import '../assets/fonts/font.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/style/style.css";
import "../assets/style/responsive.css";



function Main() {
  return (
    <>
      <React.Fragment>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />

            <Route exact path="/home/auth/login" element={<Login />} />
            <Route exact path="/home/auth/register" element={<Register />} />
            <Route exact path="/home/practice-test" element={<PracticeTest />} />   

            <Route exact path="/home/terms-conditions" element={<TermsConditions />} />
            <Route exact path="/home/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/home/cookie-policy" element={<CookiePolicy />} />   
            
            <Route exact path="/subscription/:id" element={<Subscription />} />
            <Route exact path="/email-verification/:id/:token" element={<Subscription />} />

            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/dashboard/start-test" element={<StartTest />} />
            <Route exact path="/dashboard/attempt-test/:id" element={<AttemptTest />} />
            <Route exact path="/dashboard/test-details/:id" element={<TestDetails />} />   
            <Route exact path="/dashboard/profile" element={<Profile />} />


      
            <Route exact path="*" element={<Errors />} />
        </Routes>
      </React.Fragment>
    </>
  );
}

export default Main;
