import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import { Container } from 'react-bootstrap';

const Headers = () => {
  return (
    <>
      <header id="header">
          <div className="header-block">
              <Container>
                  <div className="header-box">
                      <Logo />
                      <Menu />
                  </div>
              </Container>
          </div>
      </header>
   </>
  )
}

export default Headers;
