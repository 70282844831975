import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';
import { $ } from 'react-jquery-plugin';
import PageTitle from '../../components/PageTitle/PageTitle';
import Content from '../../components/Content/Content';
// import NewsCard from '../../components/NewsCard/NewsCard';

import { Container, Row, Col } from 'react-bootstrap';

// import Bannerimg from '../../assets/images/BANNER.webp';
// import Aboutimg from '../../assets/images/about.webp';
// import News1 from '../../assets/images/News1.webp';
// import News2 from '../../assets/images/News2.webp';
// import News3 from '../../assets/images/News3.webp';

import { FaAngleRight, FaLock, FaPhone, FaEnvelope, FaGlobe, FaLocationDot } from "react-icons/fa6";

import { getQuestionSet, getHomeQuestionSet } from '../../apiservices/ApiServices';
import Loderimg from '../../assets/images/loder.gif';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { contactSchema } from '../../schemas';
import { postContact } from '../../apiservices/ApiServices';

const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
}

const Home = () => {
    const [isLoading, setLoading] = useState(true);
    const [isLoadings, setLoadings] = useState(true);
    const [isContact, setContact] = useState(false);
    const [isData, setData] = useState([]);
    const [homeData, setHomeData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [pTestInfo, setPTestInfo] = useState([]);

    const [isUseLog, setUeLog] = useState(false);
    const [isSubscrip, setSubscrip] = useState(false);
    const [isPTest, setPTest] = useState(false);

    const totalCorrectAnswers = [];
    const totalWrongAnswers = [];

    useEffect(() => {
        if (!localStorage.getItem('P_Test')) {
            setPTest(false);
            
        }
        else {
            let ptestdata = JSON.parse(localStorage.getItem('P_Test'))
            if (ptestdata.length === 0) 
            {
                setPTest(false);
            }
            else {
                setPTestInfo(ptestdata);
                setPTest(true);
            }
            
            
        }



        if (!localStorage.getItem('userToken')) {
            setUeLog(false);
            
        }
        else {
            let userdata = JSON.parse(localStorage.getItem('userInfo'))
            setUserInfo(userdata);
            setUeLog(true);
            getQuestionSet().then((res) => {
                try {
                    setData(res.data.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoadings(false);
                }
            });

            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
                setSubscrip(true);
            }
            else {
                setSubscrip(false);
            }

        }        
        getHomeQuestionSet().then((res) => {
            try {
                setHomeData(res.data.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        });

        $(document).on('click', '#nav ul li a.offsets', function () {
            var url = $(this).attr('href');
            var str = url.substring(url.indexOf('#') + 1);

            if (str != '' && $('#' + str).length > 0) {

                if ($(window).width() <= 1024) {
                    $('html, body').animate(
                        {
                            scrollTop: $('#' + url.substring(url.indexOf('#') + 1)).offset().top - 90
                        }, 500);
                }
                else {
                    $('html, body').animate(
                        {
                            scrollTop: $('#' + url.substring(url.indexOf('#') + 1)).offset().top - 70
                        }, 500);
                }
            }
        });

        
    }, [])


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: contactSchema,
        onSubmit: (vlaues, action) => {
            setContact(true);
            postContact(vlaues).then((res) => {
                try {
                    if (res.data.success === true) {
                        setContact(false);
                        toast.success(res.data.message);
                        action.resetForm();
                    }
                    else {
                        setContact(false);
                        if (res.data.data.name) {
                            toast.error(res.data.data.name);
                        }

                        if (res.data.data.email) {
                            toast.error(res.data.data.email);
                        }

                        if (res.data.data.subject) {
                            toast.error(res.data.data.subject);
                        }

                        if (res.data.data.message) {
                            toast.error(res.data.data.message);
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });

        }
    })

    return (
        <>
            <Headers />

            {/* <section id='banner'>
                <img src={Bannerimg} alt='banner' title='banner' />
            </section> */}
            <ToastContainer />
            <section id='aboutus'>
                <div className='about-block'>
                    <Container>
                        <div className='about-box'>
                            <Row>
                                {/* <Col md={6}>
                                    <div className='about_img'>
                                        <img src={Aboutimg} alt='About Us' title='About Us' />
                                    </div>
                                </Col> */}
                                <Col md={12}>
                                    <div className='about_content'>
                                        {/* <PageTitle CName='section-title' Title="About Us" /> */}
                                        <PageTitle CName='content-title' Title="Mock Tests" />
                                        <Content CName="txtj" Content="CTJ.je is your go-to destination for Citizenship Exam preparation. Our website offers comprehensive test practice sets designed to help you ace your citizenship exam with confidence. Whether you're aiming for naturalization or immigration, our user-friendly platform ensures efficient learning and preparation. Get ready to succeed with 3 easy Steps." />
                                        <ul className='listcontent'>
                                            <li>1. Create User/Login</li>
                                            <li>2. Subscribe</li>
                                            <li>3. Start giving Mock tests</li>
                                        </ul>
                                        <br />
                                        <p>Good Luck!</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </section>

            <section id='tests'>
                <div className='tests-block'>
                    <Container>
                        <div className='tests-box'>
                            <PageTitle CName='section-title' Title="Select your Test set & start preparing" />
                            {isSubscrip ? (
                                <div>
                                    {isLoadings ? (
                                        <div className='pageloder'>
                                            <img src={Loderimg} alt='Loder' title='Loder' />
                                        </div>
                                    ) : (
                                        <Row>
                                            {isData.questionSets.map((item) => {
                                                return (
                                                    <Col md={3} key={item.id}>
                                                        <Link className={item.isAttempted == 1 && item.isPass == 1 ? 'cards passed' : item.isAttempted == 1 && item.isPass == 0 ? 'cards failed' : 'cards'} to={'/dashboard/attempt-test/' + item.id}>
                                                            <div className='content'>
                                                                <h4>{item.question_set_name}</h4>
                                                                {item.isAttempted == 0 ? (<Content Content="Not Attempted" />) : (
                                                                    <>
                                                                        <Content Content="Attempted" />
                                                                        <Content Content={'Last test score :' + item.lastTestScore + '/' + item.totalQuestions} />
                                                                        <Content Content={'No. of Tries :' + item.noOfTries} />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className='icons'>
                                                                <FaAngleRight />
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {isLoading ? (
                                        <div className='pageloder'>
                                            <img src={Loderimg} alt='Loder' title='Loder' />
                                        </div>
                                    ) : (
                                        <Row>
                                            <>
                                                {isPTest ? (
                                                    <>
                                                    {pTestInfo.testData.optionData.map((item) => {
                                                        if (item.is_correct != 0) {
                                                            totalCorrectAnswers.push(item.is_correct)
                                                        }
                                                        else {
                                                            totalWrongAnswers.push(item.is_correct)
                                                        }
                                                    })}
                                                    </>
                                                ): null}
                                            </>
                                        
                                            {homeData.ukTest.map((item, index) => {
                                                return (
                                                    <Col md={3} key={item.id}>
                                                        <Link className={index == 0 ? 'cards firsts' : 'cards'} to={index == 0 ? '/home/practice-test' : 
                                                            !isUseLog ? "/home/auth/login" : "/subscription/"+userInfo.id
                                                        }>
                                                            <div className='content'>
                                                                <h4>{item.question_set_name}</h4>
                                                                {index == 0 ? <>
                                                                    {isPTest ? <p className={pTestInfo.testData.optionData.length/2 <= totalCorrectAnswers.length ?'pass': 'fail'}>{totalCorrectAnswers.length} of {pTestInfo.testData.optionData.length} answered correctly</p> : <Content Content="Test Not Completed" />}
                                                                   
                                                                </> : <>
                                                                    {!isUseLog ? <Content Content="Login Required" />: <Content Content="Subscription Required" />}
                                                                </>}
                                                            </div>
                                                            
                                                            <div className='icons'>
                                                                {index == 0 ? <FaAngleRight /> : <FaLock className='cloros' />}
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </div>
                            )}
                        </div>
                    </Container>

                </div>
            </section>

            {/* <section id='news'>
                <div className='news-block'>
                    <Container>
                        <div className='news-box'>
                            <PageTitle CName='section-title w' Title="News" />
                            <Row>
                                <Col md={4}>
                                    <NewsCard Title="Coaching Program For Freshers" Imgurl={News1} Dates="07 Nov, 2023" Content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." />
                                </Col>
                                <Col md={4}>
                                    <NewsCard Title="Coaching Program For Freshers" Imgurl={News2} Dates="07 Nov, 2023" Content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." />
                                </Col>
                                <Col md={4}>
                                    <NewsCard Title="Coaching Program For Freshers" Imgurl={News3} Dates="07 Nov, 2023" Content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </section> */}

            <section id='contactus'>
                <div className='contactus-block'>
                    <Container>
                        <PageTitle CName='section-title' Title="Get in Touch" />
                        {/* <PageTitle CName='content-title' Title="Tittle Here" /> */}
                        <div className='contactus-box mt-0'>
                            <Row className='flexend'>
                                {/* <Col md={5}>
                                    {isLoading ? (
                                        <div className='pageloder'>
                                            <img src={Loderimg} alt='Loder' title='Loder' />
                                        </div>
                                    ) : (
                                        <div className='forms_boxs contactinfo'>
                                            <h4>Contact Information</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            <div className='contact'>
                                                <div className='icons'>
                                                    <FaPhone />
                                                </div>
                                                <p><Link to={'tel:' + homeData.contactInformation.contact}>{homeData.contactInformation.contact}</Link></p>
                                            </div>
                                            <div className='contact'>
                                                <div className='icons'>
                                                    <FaEnvelope />
                                                </div>
                                                <p><Link to={'mailto:' + homeData.contactInformation.email}>{homeData.contactInformation.email}</Link></p>
                                            </div>
                                            <div className='contact'>
                                                <div className='icons'>
                                                    <FaGlobe />
                                                </div>
                                                <p><Link to={homeData.contactInformation.website}>{homeData.contactInformation.website}</Link></p>
                                            </div>
                                            <div className='contact'>
                                                <div className='icons'>
                                                    <FaLocationDot />
                                                </div>
                                                <p>{homeData.contactInformation.address}</p>
                                            </div>
                                        </div>
                                    )}
                                </Col> */}
                                <Col md={7}>
                                    <div className='forms_boxs'>
                                        <form id='conatcform' onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <label htmlFor='name'>Name *</label>
                                                        <input type="text"
                                                            name='name'
                                                            id='name'
                                                            placeholder='Enter Name'
                                                            className='form-control'
                                                            autoComplete='off'
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <p className='errormsg'>{errors.name}</p>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='form-group'>
                                                        <label htmlFor='email'>Email *</label>
                                                        <input type="email"
                                                            name='email'
                                                            id='email'
                                                            placeholder='Enter Email Address'
                                                            className='form-control'
                                                            autoComplete='off'
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <p className='errormsg'>{errors.email}</p>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='form-group'>
                                                <label htmlFor='subject'>Subject *</label>
                                                <input type="text"
                                                    name='subject'
                                                    id='subject'
                                                    placeholder='Enter Subject'
                                                    className='form-control'
                                                    autoComplete='off'
                                                    value={values.subject}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.subject && touched.subject ? (
                                                    <p className='errormsg'>{errors.subject}</p>
                                                ) : null}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='message'>Message *</label>
                                                <textarea type="text"
                                                    name='message'
                                                    id='message'
                                                    placeholder='Enter Message'
                                                    className='form-control'
                                                    autoComplete='off'
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                ></textarea>
                                                {errors.message && touched.message ? (
                                                    <p className='errormsg'>{errors.message}</p>
                                                ) : null}
                                            </div>
                                            <div className='btnboxs'>
                                                <button type='submit' className='btn-white'>Send Message</button>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </section>

            {isContact ? (
                <div className='pageloder'>
                    <img src={Loderimg} alt='Loder' title='Loder' />
                </div>
            ) : null}

            <Footers />
        </>
    )
}

export default Home;