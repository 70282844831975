import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Main from './pages/Main';
import { getCheckStatus } from './apiservices/ApiServices';


function App() {
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, 'I') ||
      ctrlShiftKey(e, 'J') ||
      ctrlShiftKey(e, 'C') ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    )
      return false;
  };
  
  useEffect(() => {
    setInterval(() => {
      if (!localStorage.getItem('userToken')) {

      }
      else {
        getCheckStatus().then((res) => {
          if (res.data.success === true) {
            localStorage.setItem('subscriptionExpired', res.data.data.isSubscriptionExpired);
          }
        });
      }
    }, 10000);
  },[]);
  return (
    <>
      <BrowserRouter>
        <Main />        
      </BrowserRouter>
    </>
  );
}

export default App;