import React, { useEffect, useState } from 'react';
import Headers from "../../includes/Headers/Headers";
import Footers from "../../includes/Footers/Footers";
import { Container } from "react-bootstrap";
import { getCms } from '../../apiservices/ApiServices';
import Loderimg from '../../assets/images/loder.gif';


const TermsConditions = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {

        let slug = 'terms-and-condition';
        getCms(slug).then((res) => {
            try {
                setData(res.data.data.data);
                console.log(res.data.data.data)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        });

    }, [])
    return (
        <>
            <Headers />
            <section id="contents">
                <div className="Content-block">
                    {isLoading ? (
                        <div className='pageloder'>
                            <img src={Loderimg} alt='Loder' title='Loder' />
                        </div>
                    ) : (
                        <Container>
                            <div className="content-boxs">
                                <div dangerouslySetInnerHTML={{ __html: data }}></div>
                            </div>
                        </Container>
                    )}
                </div>
            </section>
            <Footers />
        </>

    )
}

export default TermsConditions