import React, { useEffect, useState } from 'react';

import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';

import { getDashboard } from '../../apiservices/ApiServices';
import Chart from "react-apexcharts";
import DataTable from "react-data-table-component";

import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";

import ChartCard from '../../components/ChartCard/ChartCard';
import ChartImg from '../../assets/images/chart.webp';
import Loderimg from '../../assets/images/loder.gif';

const Dashboard = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);

  const [Categories, SetCategories] = useState([]);
  const [data, setdata] = useState([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    const seriesData = [];
    const categoriesData = [];

    if (!localStorage.getItem('userToken')) {
      navigate("/");
    }
    else {
      let subscrip = localStorage.getItem('subscriptionExpired')
      if(subscrip == 0) {
        getDashboard().then((res) => {
          try {
            setDatas(res.data.data);

            res.data.data.report.map(item =>{
              categoriesData.push(item.attempted_date)
              seriesData.push(item.percentage)
            })
            SetCategories(categoriesData);
            setdata(seriesData);

          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        });
      }
      else {
        navigate("/dashboard/profile");
      }
    }
  }, [])



  const customStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '12px', // override the cell padding for head cells
        paddingRight: '12px',
        background: '#B60011',
        fontFamily: 'Comme-Bold',
        fontSize: '15px',
        color: '#fff',
      },
    },
    cells: {
      style: {
        paddingLeft: '12px', // override the cell padding for head cells
        paddingRight: '12px',
        background: '#fff',
        fontFamily: 'Comme-Regular',
        fontSize: '15px',
        color: '#3E4954',
      },
    },
  };

  const columns = [
    {
      name: 'Test Name',
      selector: row =>  <a href={'/dashboard/test-details/'+row.id}>{row.question_set_name}</a>,
      sortable: true,

    },
    {
      name: 'Last Date',
      selector: row => row.attempted_date,
      sortable: true,

    },
    {
      name: 'Score',
      selector: row => row.total_correct,
      sortable: true,

    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,

    },
  ];

  return (
    <>
      <Headers />
      <div className='pagewapper'>
        {isLoading ? (
          <div className='pageloder'>
            <img src={Loderimg} alt='Loder' title='Loder' />
          </div>
        ) : (
          <Container>
            <div className='pagetitles_box'>
              <div className='left_boxs'>
                <h1>Dashboard</h1>
                <p>Dashboard</p>
              </div>
              <div className='right_boxs'>
                <Link to={'/dashboard/start-test'} className='btn btn-red io'><div className='cricleio'><FaPlus /></div>Start Test</Link>
              </div>
            </div>
            <div className='chartslist'>
              <Row>
                <Col md={3}>
                  <ChartCard Title="Total Test Attempted" DataValue={datas.totalTestAttempt} ImgUrl={ChartImg} />
                </Col>
                <Col md={3}>
                  <ChartCard Title="Average Score" DataValue={datas.avarageScore} ImgUrl={ChartImg} />
                </Col>
                <Col md={3}>
                  <ChartCard Title="Last Test Date" DataValue={datas.lastTestDate? datas.lastTestDate: '--'} ImgUrl={ChartImg} />
                </Col>
                <Col md={3}>
                  <ChartCard Title="Last Attempted Score" DataValue={datas.lastAttemptedScore} ImgUrl={ChartImg} />
                </Col>
              </Row>
            </div>
            <div className="matric">
              <Row>
                <Col md={6}>
                  <div className="mixed-chart cards">
                    <Chart options={{                     
                      chart: {
                        height: 350,
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                          show: true
                        },
                        zoom: {
                          enabled: true
                        }
                      },
                      fill: {
                        colors: [function({ value, seriesIndex, w }) {
                          if(value < 20) {
                              return '#b60011'
                          } else if (value >= 15 && value < 40) {
                              return '#000'
                          } else {
                              return '#000'
                          }
                        }]
                      },
                      // plotOptions: {
                      //       bar: {
                      //         columnWidth: '60px'
                      //       }
                      // },
                      title: {
                        text: 'Report',
                        align: 'left'
                      },                      
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                          }
                        }
                      }],
                      xaxis: {
                        categories: Categories
                      },
                      noData: {
                      text: 'There are no records to display',
                      align: 'center',
                      verticalAlign: 'middle',
                    }
                                        }}
                    series={[{
                      data: data
                    }]} type="bar" height={350} />
                    {/* <Chart options={state.options} series={state.series} type="bar" height={350} /> */}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="tabels cards">
                    <DataTable
                      columns={columns}
                      data={datas.testList}
                      customStyles={customStyles}
                      pagination
                      {...props}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </div>
      <Footers />
    </>
  )
}

export default Dashboard
