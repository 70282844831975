import React, { useState, useEffect } from 'react';
import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';

import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Loderimg from '../../assets/images/loder.gif';
import Table from 'react-bootstrap/Table';

import axios from 'axios';
import { postProfile, postRegiterOtp, getCheckStatus, getprofile, postPaymentCancel, getPaymentDetails } from '../../apiservices/ApiServices';

import { useFormik } from 'formik';
import { signupSchema } from '../../schemas';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaUser, FaEnvelope, FaMobileScreenButton, FaCheck } from "react-icons/fa6";


const Profile = () => {
    const [isLoading, setLoading] = useState(false);
    const [isLoadings, setLoadings] = useState(true);
    const [data, setdata] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [subscrip, setSubscrip] = useState('2');

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();
    

    useEffect(() => {
        if (!localStorage.getItem('userToken')) {
            navigate("/");
        }
        else {
            setLoading(true)
            setInterval(() => {
                let subscrips = localStorage.getItem('subscriptionExpired');
                setSubscrip(subscrips);
            }, 2000);

            getCheckStatus().then((res) => {
                if (res.data.success === true) 
                {
                    localStorage.setItem('subscriptionExpired', res.data.data.isSubscriptionExpired);
                    setSubscrip(res.data.data.isSubscriptionExpired);
                }
            });

            getprofile().then((res) => {
                try {
                    setdata(res.data.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false)
                }
            });
    
            getPaymentDetails().then((res) => {
                try {
                    setPaymentDetails(res.data.data.paymentData);
                    console.log(res.data.data.paymentData)
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoadings(false)
                }
            });

        }
    }, [])



    const usersItems = JSON.parse(localStorage.getItem('userInfo'));

    let uid = usersItems.id;

    const initialValues = {
        firstName: usersItems.first_name,
        lastName: usersItems.last_name,
        email: usersItems.email,
        mobile: usersItems.mobile,
        otp: '',
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: initialValues,
        validationSchema: signupSchema,

        onSubmit: (vlaues) => {
            setLoading(true);
            postProfile(vlaues).then((res) => {
                try {
                    if (res.data.success === true) {
                        setLoading(false);
                        toast.success(res.data.message);
                        localStorage.setItem('userInfo', JSON.stringify(res.data.data.user));
                    }
                    else {
                        setLoading(false);
                        if (res.data.data.mobile) {
                            toast.error(res.data.data.mobile[0]);
                        }

                        if (res.data.data.email) {
                            toast.error(res.data.data.email[0]);
                        }

                        if (res.data.data.otp) {
                            toast.error(res.data.data.otp[0]);
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {

                }
            });

        },

        handleReset: (action) => {
            action.resetForm();
        }
    })

    const cancelSubscription = () => {
        let id = data.user.id;
        let order_id = data.payment_data.order_id;
        let subscription_id = data.payment_data.subscription_id;

        setLoading(true);
        axios({
            url: 'https://api.paypal.com/v1/oauth2/token',
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Accept-Language': 'en_US',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            auth: {
                username: 'AVvFT9qvr1QxZT8nK-hdHeYgX4Sc79iX3zW_YJiddRKanWqTOi6OD00PyjXog4mO8-KtY5Tm7X6x1SGP',
                password: 'EADSGQaI-MlwlBr9Hyk6Bz92VGCnhgbsKAxtUPd3SrlOIWmQb0MXUJ3tKRbYvuSchHji7PewXzMjFjWq'
            },
            data: {
                grant_type: 'client_credentials'
            }
        })
            .then(res => {
                try {
                    console.log(res.data.access_token);
                    axios({
                        //url: 'https://api.paypal.com/v1/billing/subscriptions/I-BW452GLLEP1G/cancel',
                        url: 'https://api.paypal.com/v1/billing/subscriptions/' + subscription_id + '/cancel',
                        method: 'post',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + res.data.access_token,
                        },
                        data: { "reason": "test -- Not satisfied with the service" }
                    })
                        .then(resp => {
                            try {

                            } catch (error) {
                                console.error(error);
                            } finally {

                                postPaymentCancel(id, order_id, subscription_id).then((res) => {
                                    try {
                                        if (res.data.success === true) {
                                            setLoading(false);
                                            toast.success(res.data.message);
                                            localStorage.setItem('subscriptionExpired', 2);
                                            setSubscrip(2);

                                            getPaymentDetails().then((res) => {
                                                try {
                                                    setPaymentDetails(res.data.data.paymentData);
                                                } catch (error) {
                                                    console.error(error);
                                                } finally {
                                                }
                                            });
                                        }
                                        else {
                                            setLoading(false);
                                        }
                                    } catch (error) {
                                        console.error(error);
                                        setLoading(false);
                                        toast.error(res.data.message);
                                    } finally {
                                        setLoading(false);
                                    }
                                });


                            }
                        });
                } catch (error) {
                    console.error(error);
                } finally {
                }
            });
    }

    function getOtp() {
        setLoading(true)
        postRegiterOtp(values.mobile).then((res) => {
            try {
                if (res.data.success === true) {
                    setLoading(false);
                    toast.success(res.data.message);
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        });
    }

    const handleRadioChange = (e) => {
        let val= e.target.value;
        if(val!='premium')
        {
            setShow(true);
        }
       
    }

    const handleAltok = () => {
        setShow(false);
        setIsChecked(true);
        cancelSubscription();
    }


    
    return (
        <>
            <Headers />
            <div className='pagewapper'>
                <ToastContainer />

                {isLoadings ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : ( <>
                    <Container className='container'>
                        <div className='pagetitles_box'>
                            <div className='left_boxs'>
                                <h1>Profile</h1>
                                <p><Link to={'/dashboard'}>Dashboard</Link> {'>'} Profile</p>
                            </div>
                            {subscrip == 0 ? (<div className='right_boxs'>
                                <button className='btn btn-red' onClick={() => setShow(true)}>Cancel Subscription</button>
                            </div>) : null}
                        </div>
                        <div className='profileboxs'>
                            <form id='profileform' onSubmit={handleSubmit} onReset={handleReset}>
                                <Row>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='firstName'><div className='icon'><FaUser /></div> First Name</label>
                                            <input type="text"
                                                name='firstName'
                                                id='firstName'
                                                placeholder='Enter First Name'
                                                className='form-control'
                                                autoComplete='off'
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.firstName && touched.firstName ? (
                                                <p className='errormsg'>{errors.firstName}</p>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='lastName'><div className='icon'><FaUser /></div> Last Name</label>
                                            <input type="text"
                                                name='lastName'
                                                id='lastName'
                                                placeholder='Enter Last Name'
                                                className='form-control'
                                                autoComplete='off'
                                                value={values.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.lastName && touched.lastName ? (
                                                <p className='errormsg'>{errors.lastName}</p>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='email'><div className='icon'><FaEnvelope /></div> Email</label>
                                            <input type="email"
                                                name='email'
                                                id='email'
                                                placeholder='Enter Email Address'
                                                className='form-control'
                                                autoComplete='off'
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.email && touched.email ? (
                                                <p className='errormsg'>{errors.email}</p>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='mobile'><div className='icon'><FaMobileScreenButton /></div> Mobile No.</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">+44</span>
                                                <input type="tel"
                                                    name='mobile'
                                                    id='mobile'
                                                    placeholder='Enter Mobile No.'
                                                    className='form-control'
                                                    maxLength={10}
                                                    aria-describedby="basic-addon1"
                                                    autoComplete='off'
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {!errors.mobile && values.mobile ? (
                                                <div className='linksbtns'>
                                                    <button type='button' onClick={getOtp}>Get OTP</button>
                                                </div>
                                            ) : null}
                                            {errors.mobile && touched.mobile ? (
                                                <p className='errormsg'>{errors.mobile}</p>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='form-group'>
                                            <label htmlFor='otp'><div className='icon'><FaMobileScreenButton /></div> OTP Verification</label>
                                            <input type="tel"
                                                name='otp'
                                                id='otp'
                                                placeholder='Enter OTP'
                                                className='form-control'
                                                autoComplete='off'
                                                maxLength={4}
                                                value={values.otp}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {!errors.mobile && values.mobile && !errors.otp && values.otp ? (
                                                <div className='linksbtns'>
                                                    <button type='button' onClick={getOtp}>Resend</button>
                                                </div>
                                            ) : null}
                                            {errors.otp && touched.otp ? (
                                                <p className='errormsg'>{errors.otp}</p>
                                            ) : null}
                                        </div>
                                    </Col>

                                </Row>
                                <div className='button_boxs'>
                                    <div>
                                        <button type='submit' className='btn btn-red'>Save</button>
                                    </div>
                                    <div>
                                        <button type='reset' className='btn btn-gray'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Container>
                    <div className='subscribe-block'>
                        <Container>
                            <h1>Subscribe for Premium Access</h1>
                            <Row>
                                <Col md={6}>
                                    <div className='subscribe-box'>
                                        <div className='subhed'>
                                            <div>
                                                <h4>Free Access</h4>
                                                <h2>£0.00 <span>/month</span></h2>
                                            </div>
                                            <div className='rdobox'>
                                                <input type='radio' name='rdosubscribe' value='free' checked={subscrip == 0 ? isChecked: true} onChange={handleRadioChange} />                                            
                                                <span className='rdomark'></span>
                                            </div>
                                        </div>
                                        <div className='accessboxs'>
                                            <p><span className='io'><FaCheck /></span>  Access to 1 Test</p>
                                            <p className='d'><span className='io'><FaCheck /></span> Personal Dashboard</p>
                                            <p className='d'><span className='io'><FaCheck /></span> Test Details</p>
                                            <p className='d'><span className='io'><FaCheck /></span> Full Progress Tracker</p>
                                            <p className='d'><span className='io'><FaCheck /></span> Audio with Question</p>
                                        </div>                                    
                                        <div className={subscrip == 0 ?'buttons': 'buttons desable'}>
                                            <Link className='btn btn-red-border' to={'/home/practice-test'}>Start Now</Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='subscribe-box'>
                                        <div className='subhed'>
                                            <div>
                                                <h4>Premium Access</h4>
                                                <h2>£5.00 <span>/month</span></h2>
                                            </div>
                                            <div className='rdobox'>
                                                <input type='radio' name='rdosubscribe' value='premium' checked={subscrip == 0 ? true: false} onChange={handleRadioChange} />
                                                <span className='rdomark'></span>
                                            </div>
                                        </div>

                                        <div className='accessboxs'>
                                            <p><span className='io'><FaCheck /></span> Access to 45 Tests</p>
                                            <p><span className='io'><FaCheck /></span> Personal Dashboard</p>
                                            <p><span className='io'><FaCheck /></span> Test Details</p>
                                            <p><span className='io'><FaCheck /></span> Full Progress Tracker</p>
                                            <p><span className='io'><FaCheck /></span> Audio with Question</p>
                                        </div>
                                        <div className={subscrip == 0 ?'buttons desable': 'buttons'}>
                                            <Link className='btn btn-red' to={'/subscription/' + uid}>Get Premium</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {subscrip == 0 ? (<div className='centerbutton'>
                                <button className='btn btn-red' onClick={() => setShow(true)}>Cancel Subscription</button>
                            </div>) : null}
                        </Container>
                    </div>                    
                    <div className='paymentdetails'>
                        <Container>
                            <h1>Subscription Transaction Details</h1>
                            <div className='tableboxs'>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Order id</th>
                                            <th>Subscription id</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    {paymentDetails.length ?(
                                        <tbody>
                                        {paymentDetails.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.order_id}</td>
                                                    <td>{item.subscription_id}</td>
                                                    <td>{item.transaction_date}</td>
                                                    {item.transaction_status == 'cancel' ? 
                                                        <td>{[item.expired_at, ' (Cancelled on ', item.cancelled_at, ')']}</td> : 
                                                        <td>{item.expired_at}</td>
                                                    }                                                    
                                                    {item.transaction_status != 'success' ? (<td>{item.transaction_status}</td>) : (
                                                        <td>
                                                            {item.account_status != '0' ? 'Expired' : 'Active'}
                                                        </td>
                                                    )}

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan={6} className='txtc'>No Data Found</td>
                                            </tr>
                                        </tbody>
                                    )}

                                    
                                </Table>
                            </div>
                        </Container>
                    </div>
                    </>
                )}
                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : null}
                {show ? (<>
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>Are you sure to Cancel Subscription ?</Alert.Heading>
                    <p>If you cancel the subscription, you will have limited access</p>
                    <hr />
                    <div className='buttons'>
                        <div>
                            <button onClick={handleAltok} className='btn btn-red'>OK</button>
                        </div>
                        <div>
                            <button onClick={() => setShow(false)} className='btn btn-gray'>Cancel</button>
                        </div>
                    </div>
                </Alert>
                <div className='overly'></div>
                </>) : null}
            </div>
            <Footers />
        </>
    )
}

export default Profile