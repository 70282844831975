import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const signupSchema = Yup.object({
    firstName: Yup.string().min(2).max(25).required('Please Enter your First Name'),
    lastName: Yup.string().min(2).max(25).required('Please Enter your Last Name'), 
    email: Yup.string().email().required('Please Enter your Email Address'), 
    mobile: Yup.string().matches(phoneRegExp, 'Mobile No. is not valid').min(10).max(10).required('Please Enter your Mobile No.'),
    otp: Yup.string().matches(/^[0-9]+$/, 'OTP is not valid').min(4).max(4).required('Please Enter OTP'),   
})

export const loginSchema = Yup.object({
    mobile: Yup.string().matches(phoneRegExp, 'Mobile No. is not valid').min(10).required('Please Enter your Mobile No.'),    
})

export const otpSchema = Yup.object({
    mobile: Yup.string().matches(phoneRegExp, 'Mobile No. is not valid').min(10).required('Please Enter your Mobile No.'),  
    otp: Yup.string().matches(phoneRegExp, 'OTP is not valid').min(4).required('Please Enter OTP'),    
})

export const profileSchema = Yup.object({
    firstName: Yup.string().min(2).max(25).required('Please Enter your First Name'),
    lastName: Yup.string().min(2).max(25).required('Please Enter your Last Name'), 
    email: Yup.string().email().required('Please Enter your Email Address'), 
    mobile: Yup.string().matches(phoneRegExp, 'Mobile No. is not valid').min(10).max(10).required('Please Enter your Mobile No.'), 
})


export const contactSchema = Yup.object({
    name: Yup.string().min(2).max(25).required('Please Enter Name'),
    email: Yup.string().email().required('Please Enter Email Address'), 
    subject: Yup.string().min(5).required('Please Enter Subject'),
    message: Yup.string().min(20).required('Please Enter Message'), 
})