import React from 'react'

const ChartCard = (props) => {
  return (
    <>
        <div className='chart_card'>
            <div className='chart_title'>
                <p>{props.Title}</p>
                <h4>{props.DataValue}</h4>
            </div>
            <div className='chartsbox'>
                <img src={props.ImgUrl} alt='chart' title='chart' />
            </div>
        </div>
    </>
  )
}

export default ChartCard