import React from 'react';
import { Link } from "react-router-dom";
import Logoimg from '../../assets/images/logos.webp'

const Logo = () => {
  return (
    <>
      <div className="logo-box">
          <Link to={'/'}>
            <img src={Logoimg} alt='Logo' title='Logo' />
          </Link>
      </div>
   </>
  )
}

export default Logo;