import React, { useEffect, useState } from 'react';
import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Content from '../../components/Content/Content';
import { FaAngleRight } from "react-icons/fa6";
import { getQuestionSet } from '../../apiservices/ApiServices';
import Loderimg from '../../assets/images/loder.gif';



const StartTest = () => {
    const [isLoading, setLoading] = useState(true);
    const [isData, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('userToken')) {
            navigate("/");
        }
        else {
            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
                getQuestionSet().then((res) => {
                    try {
                        setData(res.data.data);
                        console.log(res.data.data)
                    } catch (error) {
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                });
            }
            else {
                navigate("/profile");
            }
        }
    }, [])
    return (
        <>
            <Headers />
            <div className='pagewapper'>
                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : (
                    <Container>
                        <div className='pagetitles_box'>
                            <div className='left_boxs'>
                                <h1>Start Test</h1>
                                <p><Link to={'/dashboard'}>Dashboard</Link> {'>'} Start Test</p>
                            </div>
                            <div className='right_boxs'>
                                <ul>
                                    <li>
                                        <div className='colorboxs' style={{ background: '#64B66C' }}></div>
                                        <p>Passed</p>
                                    </li>
                                    <li>
                                        <div className='colorboxs' style={{ background: '#F7736C' }}></div>
                                        <p>Failed</p>
                                    </li>
                                    <li>
                                        <div className='colorboxs' style={{ background: '#959595' }}></div>
                                        <p>Not Attempted</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id='tests' className='cards'>
                            <Row>
                                {isData.questionSets.map((item) => {
                                    return (
                                        <Col md={3} key={item.id}>
                                            <Link className={item.isAttempted == 1 && item.isPass == 1 ? 'cards passed' : item.isAttempted == 1 && item.isPass == 0 ? 'cards failed' : 'cards'} to={'/dashboard/attempt-test/' + item.id}>
                                                <div className='content'>
                                                    <h4>{item.question_set_name}</h4>
                                                    {item.isAttempted == 0 ? (<Content Content="Not Attempted" />) : (
                                                        <>
                                                            <Content Content="Attempted" />
                                                            <Content Content={'Last test score :' + item.lastTestScore + '/' + item.totalQuestions} />
                                                            <Content Content={'No. of Tries :' + item.noOfTries} />
                                                        </>
                                                    )}
                                                </div>
                                                <div className='icons'>
                                                    <FaAngleRight />
                                                </div>
                                            </Link>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </Container>
                )}
            </div>
            <Footers />
        </>
    )
}

export default StartTest;