import React, { useEffect, useRef, useState } from "react";
import { $ } from 'react-jquery-plugin';

import Headers from '../../includes/Headers/Headers';
import Footers from '../../includes/Footers/Footers';

import { Container, Row, Col } from 'react-bootstrap';
import Clock from '../../assets/images/clock.png';

import { Link, useNavigate, useParams } from "react-router-dom";
import { getQuestions, postSubmitTest } from "../../apiservices/ApiServices";
import Loderimg from '../../assets/images/loder.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AttemptTest = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [number, setNumber] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [isLoadings, setLoadings] = useState(false);
    const [isData, setData] = useState([]);
    const [isques, setQues] = useState([]);

    const [secounds, setSecounds] = useState(59);
    const [minutes, setMinutes] = useState(44);
    const audioRef = useRef(null);

    useEffect(() => {
        if (!localStorage.getItem('userToken')) {
            navigate("/");
        }
        else {
            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
            getQuestions(id).then((res) => {
                try {
                    setData(res.data.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            var datachkms = [];
            var dataArray = { "testData": { "questionSetId": id, "optionData": [] } }
            $(document).on("click", ".radioboxs input", function () {
                var typecode = $(this).attr('data-typecode');
                var id = $(this).attr('data-id');
                var question_id = $(this).attr('data-qesid');
                var option = $(this).attr('data-option');
                var is_correct = $(this).attr('data-val');

                if (typecode == 'MS') {
                    datachkms.push({ "is_correct": is_correct });
                    $(this).attr("disabled", true);
                    
                    if (datachkms.length == 2) {
                        if (datachkms[0].is_correct == 1 && datachkms[1].is_correct == 1) {
                            dataArray.testData.optionData.push({"id": id, "question_id": question_id, "option": option, "is_correct": 1 })
                            setQues(dataArray);
                        }
                        else
                        {
                            dataArray.testData.optionData.push({"id": id, "question_id": question_id, "option": option, "is_correct": 0})
                            setQues(dataArray);
                        }
    
                        $(this).parents('fieldset').find('input').attr("disabled", true);
                        $(this).parents('fieldset').find('button').show();
                        datachkms = [];
                    }
                }
                else {
                    if (is_correct == 0) {
                        $(this).parents('.formsbox').find('.incorrectbox').show();
                        $(this).parents('.formsbox').find('input[data-val="1"]').parent().addClass('active');
                    }

                    $(this).parents('fieldset').find('input').attr("disabled", true);
                    $(this).parents('fieldset').find('button').show();

                    dataArray.testData.optionData.push({ "id": id, "question_id": question_id, "option": option, "is_correct": is_correct })
                    setQues(dataArray);
                }
            });

            $(document).on('click', '.next', function (e) {
                var curent_fs = $(this).parent()
                var next_fs = $(this).parent().next();

                curent_fs.hide();
                next_fs.show();

            });
        }
            else {
                navigate("/dashboard/profile");            }
        }
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
          if (secounds > 0) {
            setSecounds(secounds - 1);
          }
          if (secounds === 0) {
            if (minutes === 0) {
              clearInterval(timer);
              if (isques.length === 0) 
              {
                var dataArray = { "testData": { "questionSetId": id, "optionData": [] } }
                setQues(dataArray);
              }
              submitForm();
            } else {
              setMinutes(minutes - 1);
              setSecounds(59);
            }
          }
        }, 1000);
    
        return () => clearInterval(timer);
      }, [secounds, minutes]);


    const handelClick = () => {
        setNumber(number + 1);
        audioRef.current.pause();
    }

    const submitForm = () => {
        setLoadings(true);
        const answerList = JSON.stringify(isques)
        console.log(answerList);
        postSubmitTest(answerList).then((res) => {
            try {
                if (res.data.success === true) {
                    setLoadings(false);
                    toast.success(res.data.message);
                    setTimeout(() => navigate("/dashboard/start-test"), 3000);
                }
                else {
                    setLoadings(false);
                    toast.error(res.data.data.testData[0]);
                    setTimeout(() => navigate("/dashboard/start-test"), 3000);
                }
            } catch (error) {
                console.error(error);
            } finally {

            }
        });
    }

    return (
        <>
            <Headers />
            <div className='pagewapper'>
                <ToastContainer />
                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : (
                    <Container>
                        <div className='pagetitles_box'>
                            <div className='left_boxs'>
                                <h1>Attempt Test</h1>
                                <p><Link to={'/dashboard'}>Dashboard</Link> {'>'} <Link to={'/dashboard/start-test'}>Start Test</Link> {'>'} Attempt Test</p>
                            </div>
                        </div>
                        <div className='tests-box'>
                            <Row>
                                <Col md={6}>
                                    <div className='test_header'>
                                        <h4>Question {number} of {isData.questions.length}</h4>
                                        <div id='timer'>
                                            <img src={Clock} alt="Clock" title="Clock" />
                                            <p>Remaining Time -  {minutes < 10 ? "0" + minutes : minutes}:{secounds < 10 ? "0" + secounds : secounds} </p>
                                        </div>
                                    </div>
                                    <form id="msform">
                                        <div id='nextsteps_forms'>
                                            {isData.questions.map((item, index) => {
                                                let Play = false;
                                                return (
                                                    <fieldset key={item.id}>
                                                        <div className='formsbox'>
                                                            <div className='qustions'>
                                                                <p>Q.{index + 1}. {item.question}</p>
                                                                {item.audio != null ? ( 
                                                                    <audio controls ref={audioRef}>
                                                                        <source src={item.audio} type="audio/ogg" />
                                                                        <source src={item.audio} type="audio/mpeg" />
                                                                        Your browser does not support the audio element.
                                                                    </audio> 
                                                                ) : null}
                                                            </div>
                                                            <div className='options'>
                                                                {item.options.map((items) => {
                                                                    return (
                                                                        <div className='radioboxs' key={items.id}>
                                                                            <input type='checkbox' name={'qus' + index} data-typecode={item.question_type_code} data-id={items.id} data-qesid={items.question_id} data-val={items.is_correct} data-option={items.option} />
                                                                            <div className='rdobtns'>
                                                                                <div className='checkmark'></div>
                                                                                <p>{items.option} </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            {item.answer_desc ? (
                                                                <div className='incorrectbox'>
                                                                    <h4>Incorrect</h4>
                                                                    <p>{item.answer_desc}</p>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        {isData.questions.length === index + 1 ? <button type="button" onClick={submitForm} className='btn btn-red'>Submit</button> : <button type="button" onClick={handelClick} className='btn btn-red next'>Next</button>}

                                                    </fieldset>
                                                );
                                            })}
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                )}
                {isLoadings ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : null}
            </div>
            <Footers />
        </>

    )
}

export default AttemptTest