import axios from './axios';

export const getHeader = () => {
    const header = {
        headers: {
            Auth: 'Basic Auth', 
            'X-CITIZENSHIP-TOKEN': 'Q0lUSVpFTlNISVA6QWRtaW5AQ0lUSVpFTlNISVA=', 
            Accept: "application/json", 
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Credentials': true,
        }
    }
    return header;
}

export const getAuthHeader = () => {
    const token = 'Bearer '+localStorage.getItem('userToken');
    const header = {
        headers: {
            Authorization: token, 
            Accept: 'application/json',
            'X-CITIZENSHIP-TOKEN': 'Q0lUSVpFTlNISVA6QWRtaW5AQ0lUSVpFTlNISVA=', 
            'Access-Control-Allow-Origin': '*', 
            'Access-Control-Allow-Credentials': true,
            "Content-Type": "application/json"
        }
    }
    return header;
}

// const getCToken = async () => {
//     await axios.get('/sanctum/csrf-cookie', getHeader());
// }
// export const getTok = async () => {
//     await getCToken();
//     return await axios.get('/sanctum/csrf-cookie', getHeader()).then(res => {
//         return res;
//     })
// }

export const postRegiterOtp = async (mobile) => {
     return await axios.post('/sendOtp', {mobile, isRegister:1}, getHeader()).then(res => {
         return res;
     })
 }

export const postRegiter = async (vlaues) => {
    //await getCToken();
    let firstName = vlaues.firstName;
    let lastName = vlaues.lastName;
    let email = vlaues.email;
    let mobile = vlaues.mobile;
    let otp = vlaues.otp
    return await axios.post('/register', {firstName,lastName,email,mobile,otp}, getHeader()).then(res => {
        return res;
    })
}

export const postLogin = async (vlaues) => {
   //await getCToken();
    let mobile = vlaues.mobile;
    return await axios.post('/sendOtp', {mobile}, getHeader()).then(res => {
        return res;
    })
}



export const postOtp = async (mobile,otp) => {
    //await getCToken();
    return await axios.post('/submitOtp', {mobile,otp}, getHeader()).then(res => {
        return res;
    })
}

export const getDashboard = async () => {
    //await getCToken();
    return await axios.get('/dashboard', getAuthHeader()).then(res => {
        return res;
    })
}

export const postProfile = async (vlaues) => {
    let firstName = vlaues.firstName;
    let lastName = vlaues.lastName;
    let email = vlaues.email;
    let mobile = vlaues.mobile;
    let otp = vlaues.otp;

    return await axios.post('/profile', {firstName,lastName,email,mobile,otp}, getAuthHeader()).then(res => {
        return res;
    })
}

export const getHomeQuestionSet = async () => {
    return await axios.get('/home', getHeader()).then(res => {
        return res;
    })
}

export const getQuestionSet = async () => {
    return await axios.get('/get-question-sets', getAuthHeader()).then(res => {
        return res;
    })
}

export const getQuestions = async (id) => {
    return await axios.get('/get-questions/'+id, getAuthHeader()).then(res => {
        return res;
    })
}

export const postSubmitTest = async (answerList) => {
    return await axios.post('/submit-test', answerList, getAuthHeader()).then(res => {
        return res;
    })
}


export const getAttemptedQuestions = async (id) => {
    return await axios.post('/attempted-questions', {id:id}, getAuthHeader()).then(res => {
        return res;
    })
}

export const postActiveEmail= async (id, token) => {
    return await axios.post('/activate', {id:id, token:token}, getAuthHeader()).then(res => {
        return res;
    })
}

export const postUserInfo= async (id) => {
    return await axios.post('/user-info', {id:id}, getAuthHeader()).then(res => {
        return res;
    })
}

export const postPayment= async (id,orderID,transaction_date,subscriptionID,accessToken) => {
    return await axios.post('/payment', {id:id,orderID:orderID,transaction_date:transaction_date,subscriptionID:subscriptionID,accessToken:accessToken}, getAuthHeader()).then(res => {
        return res;
    })
}

export const getCheckStatus = async (id) => {
    return await axios.get('/check-status', getAuthHeader()).then(res => {
        return res;
    })
}

export const getPracticeQuestions = async () => {
    return await axios.get('/get-practice-questions', getHeader()).then(res => {
        return res;
    })
}

export const postContact = async (vlaues) => {
    let name = vlaues.name;
    let email = vlaues.email;
    let subject = vlaues.subject;
    let message = vlaues.message;

    return await axios.post('/contact-us', {name,email,subject,message}, getHeader()).then(res => {
        return res;
    })
}

export const getCms = async (slug) => {
    return await axios.get('/cms-pages/'+slug, getHeader()).then(res => {
        return res;
    })
}


export const getprofile = async () => {
    return await axios.get('/profile', getAuthHeader()).then(res => {
        return res;
    })
}

export const postPaymentCancel = async (id,order_id,subscription_id) => {
    return await axios.post('/payment-cancel', {id,order_id,subscription_id}, getHeader()).then(res => {
        return res;
    })
}

export const getPaymentDetails = async () => {
    return await axios.get('/payment-details', getAuthHeader()).then(res => {
        return res;
    })
}