import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { postLogin, postOtp, getCheckStatus } from '../apiservices/ApiServices';

import { useFormik } from 'formik';
import { loginSchema } from '../schemas';

import Logoimg from '../assets/images/logo.webp';
import { FaMobileScreenButton } from "react-icons/fa6";
import Loderimg from '../assets/images/loder.gif';

import {Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
    mobile: '',
}

const Login = () => {
    const navigate = useNavigate();
    useEffect (() => {
      if(localStorage.getItem('userToken'))
      {
            let subscrip = localStorage.getItem('subscriptionExpired')
            if (subscrip == 0) {
                navigate("/dashboard");       
            }
            else{
                navigate("/");
            }
      }
    },[navigate])
    
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [otp, setOtp] = useState('');
    const [mobile, SetMobile] = useState('');

    const handleChanges = (otp) => setOtp(otp);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,

        onSubmit: (vlaues, action) => {
            setLoading(true);            
            postLogin(vlaues).then((res) => {
                try {
                    if(res.data.success === true)
                    { 
                        setLoading(false);
                        toast.success(res.data.message);
                        
                        setSuccess(true);
                        SetMobile(vlaues.mobile);

                        action.resetForm();
                        console.log('OTP => ' + res.data.data.otp)                      
                    }
                    else
                    {
                        setLoading(false);
                        toast.error(res.data.data.mobile[0]);
                        //toast.error(res.data.message);
                        console.log(res.data.data.mobile);
                    }
                } catch (error) {
                    console.error(error);
                } finally {                   
                    
                }                
            });

        }
    })

    function Submitotp() {
        
        postOtp(mobile,otp).then((res) => 
        {
            try {
                if(res.data.success === true)
                { 
                    setLoading(false);
                    toast.success(res.data.message);
                    
                    localStorage.setItem('userToken', res.data.data.token);
                    localStorage.setItem('userInfo', JSON.stringify(res.data.data.user));



                    getCheckStatus().then((ress) => {
                        if (ress.data.success === true) {
                            let subscrip = ress.data.data.isSubscriptionExpired

                            if (subscrip == 0) {
                                setTimeout(() => navigate("/dashboard"), 3000);       
                            }
                            else{
                                setTimeout(() => navigate("/subscription/"+res.data.data.user.id), 3000);
                            }   
                        }
                        else {
                            setTimeout(() => navigate("/"), 3000);
                        }
                      });

                                                  
                }
                else
                {
                    setLoading(false);
                    toast.error(res.data.data.otp[0]);
                }
            } catch (error) {
                console.error(error);
            } finally {                   
                
            } 
        });
    }
    return (
        <>
            <div id='auth'>
                <div className='auth-block login'>
                    <ToastContainer />
                    <Container>
                        <div className='auth-forms'>
                            <div className='logo_box'>
                                <img src={Logoimg} alt='Logo' title='Logo' />
                            </div>
                            {isSuccess ? (
                                <div className='formsboxs'>
                                    <div className='titles'>
                                        <h4>OTP Verification</h4>
                                        <p>Enter the OTP sent to {mobile}</p>
                                    </div>
                                    <div id='otpform'>
                                        <div className='form-group'>
                                            <OtpInput
                                                value={otp}
                                                onChange={handleChanges}
                                                numInputs={4}
                                                isInputNum={true}
                                                inputType = 'tel'
                                                shouldAutoFocus={true}                                                
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                        {otp.length >= 1 && otp.length <= 3 ? (
                                            <p className='errormsg'>Please Enter OTP</p>
                                        ) : null}
                                        
                                        <div className='buttonbox'>
                                            {otp.length <= 3 ? <button type='button' disabled className='btn btn-red'>Verify & Login</button> :
                                            <button type='submit' disabled=""  onClick={Submitotp} className='btn btn-red'>Verify & Login</button> 

                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='formsboxs'>
                                    <div className='titles'>
                                        <h4>Enter your phone number</h4>
                                        <p>We will send you the 4 digit verification code</p>
                                    </div>
                                    <form id='loginform' onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='mobile'><div className='icon'><FaMobileScreenButton /></div> Mobile No.</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">+44</span>
                                                <input type="tel"
                                                    name='mobile'
                                                    id='mobile'
                                                    placeholder='Enter Mobile No.'
                                                    className='form-control'
                                                    maxLength={10}
                                                    aria-describedby="basic-addon1"
                                                    autoComplete='off'
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {errors.mobile && touched.mobile ? (
                                                <p className='errormsg'>{errors.mobile}</p>
                                            ) : null}
                                        </div>
                                        <div className='buttonbox'>
                                            <button type='submit' className='btn btn-red'>Get OTP</button>
                                        </div>
                                        <div className='linksbox'>
                                            <p>Don't have an account ? <Link to={'/home/auth/register'}>Register</Link></p>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>

                {isLoading ? (
                    <div className='pageloder'>
                        <img src={Loderimg} alt='Loder' title='Loder' />
                    </div>
                ) : null}

                
            </div>
        </>
    )
}

export default Login;