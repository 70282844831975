import React from 'react'

const PageTitle = (props) => {
  return (
    <>
        <div className={props.CName}>
            <h1>{props.Title}</h1>
        </div>
    </>
  )
}

export default PageTitle